// 获取是否在app内
if (typeof LonchJsApi === 'undefined') {
  window.LonchJsApi = window.webkit && window.webkit.messageHandlers ? window.webkit.messageHandlers : {};
}
/**
 * 判断是否在IosApp中
 */
LonchJsApi.isInIosApp = function () {
  return (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['webCallApp']) || LonchJsApi.clientType === 'iosApp';
};

/**
 * 判断是否在IosAppV2
 */
LonchJsApi.isInIosAppV2 = function () {
  return (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['webCallAppV2'] && window.webkit.messageHandlers['iAmIos']) || LonchJsApi.clientType === 'iosAppV2';
};

/**
 * 判断是否在IosApp中
 */
LonchJsApi.isInMacApp = function () {
  return (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['webCallMac']) || LonchJsApi.clientType === 'macApp';
};

/**
 * 判断是否在IosAppV2
 */
LonchJsApi.isInMacAppV2 = function () {
  return (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['webCallAppV2'] && window.webkit.messageHandlers['iAmMac']) || LonchJsApi.clientType === 'macAppV2';
};

/**
 * 判断是否在AndroidApp中
 */
LonchJsApi.isInAndroidApp = function () {
  return typeof LonchJsApi.webCallApp === 'function' || LonchJsApi.clientType === 'androidApp';
};

/**
 * 判断是否在AndroidAppV2
 */
LonchJsApi.isInAndroidAppV2 = function () {
  return typeof LonchJsApi.webCallAppV2 === 'function' && typeof LonchJsApi.iAmAndroid === 'function';
};

/**
 * 判断是否在windowsApp中
 */
LonchJsApi.isInCef = function () {
  return typeof LonchJsApi.webCallCef === 'function' || LonchJsApi.clientType === 'windowApp';
};

/**
 * 判断是否在windowsAppV2
 */
LonchJsApi.isInCefV2 = function () {
  return typeof LonchJsApi.webCallAppV2 === 'function' && typeof LonchJsApi.iAmPC === 'function';
};
/**
 * 获取设置为LonchApp，目的在浏览器模拟App环境流程
 * localStorage.setItem('ClientType', 'LonchApp')
 * localStorage.setItem('ClientType', 'LonchAppV2')
 */
LonchJsApi.getClientType = function () {
  return localStorage.getItem('ClientType');
};
/**
 * 判断是否为支持2.0协议的App
 * @return {*|boolean|y.webkit|webkit}
 */
LonchJsApi.isInAppV2 = function () {
  return (
    LonchJsApi.isInIosAppV2()
    || LonchJsApi.isInMacAppV2()
    || LonchJsApi.isInAndroidAppV2()
    || LonchJsApi.isInCefV2()
    || LonchJsApi.getClientType() === 'LonchAppV2'
  );
};
/**
* 判断是否在App中运行
*/
LonchJsApi.isInApp = function () {
  return (
    LonchJsApi.isInIosApp()
    || LonchJsApi.isInMacApp()
    || LonchJsApi.isInAndroidApp()
    || LonchJsApi.isInCef()
    || LonchJsApi.isInAppV2()
    || LonchJsApi.getClientType() === 'LonchApp'
  );
};
